.home-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    font-family: Arial, sans-serif;
    text-align: center;
  }
  
  h1 {
    color: #333;
    margin-bottom: 30px;
  }
  
  .search-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    max-width: 90%;
  }
  
  .champion-search {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
  }
  
  input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 20px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s;
  }
  
  input[type="text"]:focus {
    border-color: #007bff;
  }
  
  .champion-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 0 0 10px 10px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 1000;
  }
  
  .champion-dropdown li {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .champion-dropdown li:hover {
    background-color: #f0f0f0;
  }
  
  .champion-dropdown img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 50%;
  }
  
  select, button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: 2px solid #ccc;
    border-radius: 20px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s;
  }
  
  select:focus, button:focus {
    border-color: #007bff;
  }
  
  button {
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #0056b3;
  }
.matchups-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .page-title {
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .search-container {
    width: 300px;
    max-width: 90%;
    margin-bottom: 20px;
  }
  
  .search-input {
    width: 100%;
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 20px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s;
  }
  
  .search-input:focus {
    border-color: #007bff;
  }
  
  .loading, .error, .no-matchups {
    text-align: center;
    margin-top: 20px;
    font-size: 18px;
    padding: 20px;
    border-radius: 8px;
  }

  .loading {
    color: #007bff;
    background-color: #e6f2ff;
  }
  
  .error {
    color: #dc3545;
    background-color: #ffe6e6;
  }

  .no-matchups {
    color: #856404;
    background-color: #fff3cd;
  }
.matchup-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
    padding: 20px;
  }
  
  .matchup-item {
    background-color: #f0f0f0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 15px;
    width: 200px;
    text-align: center;
    transition: transform 0.3s ease;
  }
  
  .matchup-item:hover {
    transform: translateY(-5px);
  }
  
  .champion-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .matchup-details {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .champion-name {
    margin: 10px 0;
    font-size: 18px;
    color: #333;
  }
  
  .win-rate, .sample-size {
    margin: 5px 0;
    font-size: 14px;
    color: #666;
  }
  
  .win-rate {
    font-weight: bold;
    color: #4caf50;
  }